html, body {
  height: 100%;
  margin: 0;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background: $light-gray;
  font-family: "Raleway", sans-serif;
  font-size: $med-size;
}

a, button {
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 4.8rem;
  font-weight: 100;
}

h2 {
  font-size: 3.6rem;
  font-weight: 700;
  padding: 1rem 0;
}

h3 {
  font-size: 2.4rem;
}

p, li {
  font-size: 1.8rem;
  line-height: calc(1.8rem * 1.618);
}

p {
  margin-bottom: 1rem;
}

.container-fluid {
  min-height: calc(100vh - $footer-height);
  background: transparent;
  padding-top: $header-size;
}

.container-sm {
  min-height: calc(100vh - $footer-height);
  background: transparent;
  padding-top: $header-size;
}