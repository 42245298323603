.main--summary {
  padding-bottom: 7rem;
}

.summary--link {
  color: $hca-gold;
  text-decoration: none;

  &:hover {
    color: darken($hca-gold, 10%);
  }
}