.form-label {
  align-items: center;
  display: flex;
}

.list-group-item {
  padding: 1rem 2rem 1rem 0;
}

.main--nomination-list {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.nomination-list--filters {
  align-items: center;
  padding: 0;
}

.nomination-list--new-entry {
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.nomination-list--search-group {
  margin: 0 1rem 0 0;
}

.nomination-list--search-filter {
  width: 65% !important;
}

.nomination-list--search-type-filter {
  width: 35% !important;
}

.nomination-list--sort-filter {
  width: 100%;
}

.nomination-list--sort-filter-label {
  margin: 0 .5rem 0 0;
}

.nomination--list {
  padding: 0 $med-size;
}

.nomination--list-item {
  background: rgba(255,255,255,.75);
  align-items: center;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 1rem;
  box-shadow: 0 .125rem .25rem rgba(#000, .075);
  margin: 0 0 $sm-size 0;
  padding: $med-size;

  &:last-child {
    margin-bottom: 0;
  }
}

.nomination-list-item--edit-button {
  margin: 0 .5rem 0 0;
}

.nomination-list--table-header {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: $med-size;
}

@media (min-width: 992px) and (max-width: 1350px) {
  .nomination-list--table-header-cell:nth-child(1),
  .nomination-list--inner-col:nth-child(1) {
    width: 10%;
  }
  .nomination-list--table-header-cell:nth-child(2),
  .nomination-list--inner-col:nth-child(2) {
    width: 33%;
  }
  .nomination-list--inner-col:nth-child(3) {
    width: 32%;
  }
  .nomination-list--inner-col:nth-child(4) {
    width: 25%;
  }
  .nomination-list--table-header-cell:nth-child(3) {
    width: 57%;
  }
}

@media (max-width: 991.98px) {
  .nomination-list--table-header {
    display: none;
    visibility: hidden;
  }
}

@media (max-width: 991.98px) {
  .nomination-list-item--edit-button {
    margin: 0 .5rem .5rem 0;
  }
}

@media (max-width: 767.98px) {
  .nomination-list--filters {
    margin-bottom: 1rem;
  }

  .nomination-list--new-entry {
    display: flex;
    margin-bottom: 3rem;
  }
}