.btn {
  background: $hca-gold;
  border-color: $hca-gold;
  color: #FFF;
  font-size: 1.6rem;

  &:hover {
    background: #D9BB4B;
    border-color: #D9BB4B;
    color: #FFF;
  }
}

.dropdown-item,
.form-control,
.form-label,
.form-select {
  color: $dark-gray;
  font-size: 1.5rem;
}

.entry-form--video-information {
  width: 100%;
  background: #F2F2F2;
  color: #000;
  font-size: 1.6rem;
  margin-bottom: $sm-size;
  padding: $med-size;

  li {
    font-size: 1.6rem;
    hyphens: auto;
  }
}

.form--video-preview {
  width: 100%;
}

.login-form__input,
.register-form__input,
.reset-password-form__input {
  margin: 0 0 1.5rem 0;
}

.main--entry-form,
.main--login-form,
.main--register-form,
.main--reset-password-form {
  padding-bottom: 7rem;
}

.main--entry-form-edit {
  padding-top: 14rem;
  padding-bottom: 7rem;
}

.main--login-form,
.main--register-form,
.main--reset-password-form {
  min-height: calc(100vh - ($header-size + $footer-height));
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 0;
  left: 0;
}