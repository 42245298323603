.main--admin {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.admin--link {
  color: $hca-gold;
  text-decoration: none;

  &:hover {
    color: darken($hca-gold, 10%);
  }
}