.header {
  width: 100vw;
  height: 30rem;
  background: linear-gradient(115deg, rgb(211, 255, 215) 0%, rgb(0, 0, 0) 100%), radial-gradient(90% 100% at 50% 0%, rgb(200, 200, 200) 0%, rgb(22, 0, 45) 100%), radial-gradient(100% 100% at 80% 0%, rgb(250, 255, 0) 0%, rgb(36, 0, 0) 100%), radial-gradient(150% 210% at 100% 0%, rgb(112, 255, 0) 0%, rgb(20, 175, 125) 0%, rgb(0, 10, 255) 100%), radial-gradient(100% 100% at 100% 30%, rgb(255, 77, 0) 0%, rgba(0, 200, 255, 1) 100%), linear-gradient(60deg, rgb(255, 0, 0) 0%, rgb(120, 86, 255) 100%);
  background-blend-mode: overlay, overlay, difference, difference, difference, normal;
  display: flex;
  padding: 0 $side-margin-size;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.header--dropdown {
  background-color: transparent !important;
  border: none;
  opacity: 0.5;

  &:hover {
    background-color: transparent;
    border: none;
    opacity: 1;
  }
}

.header--logo {
  width: 19rem;
  margin-top: 2.3rem;
}

.header--title {
  color: white;
  margin: auto 0;
}

.header--top-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.header--top-bar a img {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.header--top-bar .header--dropdown-profile.dropdown {
  top: 23px;
}

.header--hamburger-icon,
.header--user-icon {
  color: #FFF;
  margin-right: 0.5rem;
}

.header--sign-out-dropdown-link {
  display: none;
  visibility: hidden;
}

@media (max-width: 885.98px) {
  .header {
    padding: 1rem $med-size;
  }
  .header--dropdown-profile {
    display: none;
    visibility: hidden;
  }

  .header--sign-out-dropdown-link {
    display: block;
    visibility: visible;
  }
}