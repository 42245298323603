.main--orders,
.main--payment-complete {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.entry-successful--button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: $sm-size;
}

.orders--link {
  color: $hca-gold;
  text-decoration: none;

  &:hover {
    color: darken($hca-gold, 10%);
  }
}

.orders--dollar-amount {
  padding: 0 0 1rem 0;
  text-align: right;
}

.orders--product-image {
  width: 6rem;
  border-radius: 15%;
}

.orders--product-text {
  display: flex;
  flex-direction: column;
}

.orders--quantity {
  color: #808080;
  font-size: 1.4rem;
}

.orders--table {
  padding: 0 0 2rem 0;
}