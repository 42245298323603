.footer {
  width: 100vw;
  height: $footer-height;
  align-items: center;
  background: $dark-gray;
  color: $light-gray;
  display: flex;
  justify-content: center;
}

.footer--legal {
  font-size: 1.4rem;
  margin: 0;
  padding: 0;
}