.header--nav-mobile-menu {
  background: #000;
  color: #FFF;

  .dropdown-item {
    padding: .5rem 1rem;
    &:hover {
      background: $hca-gold;
      color: #FFF;
    }
  }
}

.nav-desktop {
  width: 100%;
  height: 3rem;
  color: #FFF;
  font-size: 1.6rem;
  z-index: 9999;
}

.nav-mobile {
  display: none;
  visibility: hidden;

  .dropdown-toggle::after {
    display: none;
    visibility: hidden;
  }
}

.active {
  border-bottom: .3rem solid $light-gray;
  color: #FFF !important;
  display: flex;
  opacity: 1 !important;
  text-decoration: none;

  &:hover {
    color: #FFF !important;
    cursor: default;
  }
}

.nav-link {
  color: #FFF;
  cursor: pointer;
  opacity: .5;
  margin: 0 3rem 0 0;
  padding: 0 0 .3rem 0;
  text-decoration: none;

  &:hover {
    color: #FFF;
    opacity: 1;
    text-decoration: none;
  }

  &:visited {
    color: #FFF;
  }

  &:last-child {
    margin: 0;
  }
}

.nav--icon {
  margin-right: .5rem;
}

@media (max-width: 885.98px) {
  .nav-desktop {
    display: none;
    visibility: hidden;
  }

  .nav-mobile {
    display: block;
    visibility: visible;
  }
}