// COLORS
$light-gray: #FCFCFD;
$dark-gray: #1A131A;
$hca-gold: #CC9A37;

// SPACING
$side-margin-size: 10rem;
$footer-height: 6rem;
$header-size: 30rem;
$sm-size: 1.2rem;
$med-size: 1.6rem;
$lrg-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;