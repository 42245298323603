.home--header {
  width: 100vw;
  height: 13rem;
  display: flex;
  justify-content: center;
  padding: 3rem $side-margin-size 0;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 1;
}

.home--footer {
  width: 100vw;
  height: $footer-height;
  align-items: center;
  background: transparent;
  color: $light-gray;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.home--logo {
  height: 20rem;
  margin: 0 0 5rem 0;
}

.home--main {
  width: 100vw;
  height: 100vh;
  align-items: center;
  background: linear-gradient(180deg, #F7D6FF 0%, #005686 100%), linear-gradient(180deg, #FFFFFF 0%, #060046 100%), linear-gradient(127.43deg, #FF0099 0%, #1A003C 100%), linear-gradient(307.27deg, #FF0000 0.37%, #3300C6 100%), radial-gradient(50% 71.96% at 50% 50%, #004584 0%, #00FFB2 100%), radial-gradient(100% 140% at 100% 0%, #5ED500 0%, #2200AA 100%);
  background-blend-mode: soft-light, overlay, difference, difference, color-burn, normal;
  color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 7rem;
  padding-bottom: 7rem;
  position: absolute;
  top: 0;
  left: 0;
}

.home--members-button-group {
  display: flex;
  flex-direction: row;
}

.home--submissions-text {
  margin-bottom: 4rem;
  text-align: center;
}

.loading-data-page {
  width: 100vw;
  height: 100vh;
  align-items: center;
  background: linear-gradient(180deg, #000346 0%, #FF0000 100%), linear-gradient(58.72deg, #0029FF 0%, #AA0014 100%), radial-gradient(100% 164.72% at 100% 100%, #FF00A8 0%, #00FF47 100%), radial-gradient(100% 148.07% at 0% 0%, #FFF500 0%, #51D500 100%);
  background-blend-mode: overlay, overlay, difference, normal;
  color: #FFF;
  display: flex;
  justify-content: center;
  text-align: center;
}

@media (max-width: 876px) {
  .home--logo {
    width: 100%;
    height: auto;
    margin: 0 0 0.5rem 0;
    padding: $med-size;
  }

  .home--submissions-text {
    font-size: 3.6rem;
  }
}