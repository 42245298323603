.line-box {
  display: flex;
  margin: 7rem 0 40px 0;
}

.tl-line {
  flex: 1;
  position: relative;
  text-align: center;
}

.tl-line:after {
  width: 50%;
  height: 2px;
  background-color: $light-gray;
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 1rem;
  z-index: -1;
}

.tl-line:after, .tl-line:before { background-color: #E5E5E5; }

.line-box h4 {
  color: #000;
  font-weight: bold;
}

.line-box h4, .line-box p {
  font-size: 1.5rem;
  margin-bottom: 0;
  padding: 0 5px;
}

.timeline { margin: 40px 0; }

.tl-line.done:after, .tl-line.done + .tl-line:before {
  background-color: $hca-gold;
}

.tl-line.sub:after { background-color: $hca-gold; }

.tl-line:not(:first-child):before {
  width: 50%;
  background-color: #FCFCFD;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  bottom: 1rem;
  left: 0;
  z-index: -1;
}

.tl-line:not(:first-child):before { background-color: #E5E5E5; }
.tl-line:last-child:after { width: 0; }
.dot-title { height: 100%; }

.dot-stroke {
  height: 25px;
  width: 25px;
  background-color: #FFF;
  border: 2px solid $hca-gold;
  border-radius: 100%;
  display: flex;
  margin: 3px auto;
  position: relative;
  top: -28px;
}

.dot-full {
  height: 25px;
  width: 25px;
  display: flex;
  position: relative;
  border-radius: 100%;
  top: -28px;
  margin: 3px auto;
  background-color: $hca-gold;
}

.dot-stroke { border: 2px solid #E5E5E5; }
.done .dot-stroke, .sub .dot-stroke { border: 2px solid $hca-gold; }

/* iPhone X ----------------------------------- */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
 .subline:before { top: -43px; }
}
@media only screen and (device-width: 812px) and (device-height: 375px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
 .subline:before { top: -31px; }
}

/* iPad portrait ----------------------------------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
 .subline:before { top: -29px; }
}

/* mobile width till 767px ----------------------------------- */
@media (max-width: 767px) {
 .subline:before { top: -30px; }
}

/* Portrait iPad Pro */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
 .subline:before { top: -23px; }
}

/* mobile width till 480px ----------------------------------- */
@media (max-width: 480px) {
 .subline:before { top: -43px; }
}
